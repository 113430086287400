import React from "react"
import styled, { css } from "styled-components"
import { font, color, ease, device } from "../../layout/global"

const SubmitButton = React.forwardRef(
  ({ text, className, hasDarkBackground }, ref) => {
    return (
      <Wrapper
        className={className}
        ref={ref}
        hasDarkBackground={hasDarkBackground}
      >
        <div className="btn-container">
          <div className="spacer"></div>
          <p>{text}</p>
          <div className="spacer">
            <span id="logo"></span>
          </div>
        </div>
      </Wrapper>
    )
  }
)

export default SubmitButton

const Wrapper = styled.button`
  background: ${props => (props.hasDarkBackground ? "#101010" : "none")};
  border: none;
  padding: 10px;
  outline: none;
  text-transform: uppercase;
  ${font.robotoBold};
  -webkit-transition: all 0.3s ${ease.out};
  -moz-transition: all 0.3s ${ease.out};
  -o-transition: all 0.3s ${ease.out};
  transition: all 0.3s ${ease.out};

  @media ${device.mobileP} {
    font-size: 1.1rem;
    height: 60px;
    width: 220px;
  }

  @media ${device.desktop} {
    font-size: 1.4rem;
    height: 85px;
    width: 275px;
  }

  &:hover {
    backdrop-filter: blur(15px);
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.16);
    cursor: pointer;
  }

  ${props =>
    props.hasDarkBackground
      ? css`
          &:hover > .btn-container p {
            color: ${color.red};
          }
          &:hover {
            cursor: pointer;
          }
        `
      : css`
          &:hover {
            backdrop-filter: blur(15px);
            box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.16);
            cursor: pointer;
          }
        `}

  .btn-container {
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 2.5fr 1fr;
    grid-template-rows: 1fr;
    align-content: center;
    align-items: center;
    margin: 0 auto;
  }

  p {
    word-break: break-word;
    text-align: center;
    color: ${props => (props.hasDarkBackground ? color.white : color.greyDark)};
    transition: all 0.3s ease-out;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .spacer {
    height: 100%;
    width: 100%;
    display: grid;
  }
  #logo {
    display: block;
    border: solid ${color.red};
    height: 60%;
    width: 40%;
    border-width: 0 4px 4px 0;
    align-self: center;
    justify-self: center;
  }
`
